import { AdministrationParams, FormState, SidePanelState } from '@/types';
import { TableColumn } from '@/components/common/table/table.types';
import { Group } from '@/views/administration/views/administration-groups/administration-groups.types';

export interface State {
  users: User[] | null;
  selectedUser: User | null;
  tableParams: Partial<AdministrationParams>;
  columns: TableColumn[];
  groups: Group[];
  usersToReplace: User[];
  sidePanelState: SidePanelState;
}

export interface User {
  email: string;
  name: string;
  lastName: string;
  status: string;
  role: string;
  permissions: string;
  uuid: string;
  language: string;
  group: Group[];
  phoneNumber: string;
  controllerNotification: boolean;
  rejectionNotification: boolean;
  emailIssuesDeadlineEnabled: boolean;
  emailIssuesBrieflyToDeadlineEnabled: boolean;
  emailIssuesEnabled: boolean;
  assignedNotification: boolean;
  smsNotificationsEnabled: boolean;
}

export interface ChangePassword extends FormState {
  password: string;
  repeatPassword: string;
}

export interface EditUser extends FormState {
  lastName: string;
  name: string;
  email: string;
}

export interface AddNewUser extends ChangePassword, EditUser {}

export enum UserFormMode {
  EDIT = 'EDIT',
  ADD = 'ADD',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
}
