import { columnTypes }  from '@/components/common/table/table.types';
import { State }        from '@/views/administration/views/administration-users/administration-users.types';
import { SidePanelState } from '@/types';

export const state: State = {
  sidePanelState: SidePanelState.EDIT_ENTITY,
  usersToReplace: [],
  users: null,
  selectedUser: null,
  tableParams: {
    searchQuery: '',
    'sortBy[0].direction': 'ASC',
    'sortBy[0].field': 'email',
  },
  columns: [
    { name: 'email', type: columnTypes.TEXT },
    { name: 'name', type: columnTypes.TEXT },
    { name: 'lastName', type: columnTypes.TEXT },
    { name: 'groups', type: columnTypes.TAGS },
    { name: 'actions', type: columnTypes.ACTIONS },
  ],
  groups: []
};
