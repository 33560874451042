import { Module }    from 'vuex';
import { State }     from '@/views/administration/views/administration-users/administration-users.types';
import { RootState } from '@/store/store.types';
import { mutations } from '@/views/administration/views/administration-users/store/administration-users.mutations';
import { actions }   from '@/views/administration/views/administration-users/store/administration-users.actions';
import { getters }   from '@/views/administration/views/administration-users/store/administration-users.getters';
import { state }     from '@/views/administration/views/administration-users/store/administration-users.state';


const store: Module<State, RootState> = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters,
};

export default store;
