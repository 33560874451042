
import { computed, ComputedRef, defineComponent, ref, Ref } from 'vue';
import { useGlobalTranslations, useTranslations }             from '@/hooks/use-translations';
import { useGlobals }                                       from '@/hooks/use-globals';
import { AdministrationParams, SearchbarSelectItem, SidePanelState } from '@/types';
import { TableColumn, TableRow }                                     from '@/components/common/table/table.types';
import cSidePanel                                                    from '@/components/common/side-panel/side-panel.component.vue';
import cUserForm                                                     from '@/views/administration/views/administration-users/components/user-form.vue';
import cAdministrationTemplate
  from '@/views/administration/components/administration-template/administration-template.component.vue';
import { User, UserFormMode }         from './administration-users.types';
import { AdministrationPagination } from '@/views/administration/administration.types';
import CDeleteConfirmation                                           from '@/views/administration/components/delete-confirmation/delete-confirmation.component.vue';


export default defineComponent({
  name: 'VAdministrationUsers',
  components: { CDeleteConfirmation, cAdministrationTemplate, cSidePanel, cUserForm },
  setup() {
    const { store, i18n } = useGlobals();
    const columns: ComputedRef<TableColumn[]> = computed(() => store.state.views.users.columns);
    const tableData: ComputedRef<TableRow[]> = computed(() => store.getters[ 'views/users/usersTableData' ]);
    const params: ComputedRef<Partial<AdministrationParams>> = computed(() => store.state.views.users.tableParams);
    const sidePanelState: ComputedRef<SidePanelState> = computed(() => store.state.views.users.sidePanelState);
    const usersToReplace: ComputedRef<SearchbarSelectItem[]> = computed(() => store.getters['views/users/usersToReplace'])
    const selectedUser: ComputedRef<User> = computed(() => store.state.views.users.selectedUser)
    const isSidePanelOpen: Ref<boolean> = ref(false);
    const sidePanelTitle: Ref<string> = ref('Add User');
    const userFormMode: Ref<UserFormMode> = ref(UserFormMode.ADD);

    store.commit('views/users/setTableParams', []);
    store.dispatch('views/users/getAllUsers');
    store.dispatch('views/users/getGroups');

    const closeSidePanel = (): void => {
      isSidePanelOpen.value = false;
      store.commit('views/users/setSidePanelState', SidePanelState.EDIT_ENTITY)
    };

    const openAddNewUserForm = (): void => {
      isSidePanelOpen.value = true;
      sidePanelTitle.value = i18n.t('users.sidePanel.addUser');
      userFormMode.value = UserFormMode.ADD;
    };

    const changeUserData = async (userUuid: string, action?: string): Promise<void> => {
      closeSidePanel();
      await store.dispatch('views/users/getSelectedUser', userUuid);

      if (action) {
        sidePanelTitle.value = i18n.t('users.sidePanel.changePassword');
        isSidePanelOpen.value = true;
        userFormMode.value = UserFormMode.CHANGE_PASSWORD;
      } else {
        userFormMode.value = UserFormMode.EDIT;
        sidePanelTitle.value = i18n.t('users.sidePanel.editUser');
        isSidePanelOpen.value = true;
      }
    };

    const updateParams = async (updatedParams: Partial<AdministrationParams>): Promise<void> => {
      store.commit('views/users/setTableParams', updatedParams);
      await store.dispatch('views/users/getAllUsers');
    };

    const goBackToEditForm = () => {
      store.commit('views/users/setSidePanelState', SidePanelState.EDIT_ENTITY)
    }

    const replaceUserAndDelete = async (replaceToUserUuid: string) => {
      await store.dispatch('views/users/replaceUser', { userUuid: selectedUser.value.uuid, replaceToUserUuid })
      store.dispatch('views/users/getAllUsers')
      closeSidePanel()
    }

    return {
      updateParams,
      useGlobalTranslations,
      openAddNewUserForm,
      closeSidePanel,
      changeUserData,
      params,
      columns,
      tableData,
      isSidePanelOpen,
      sidePanelTitle,
      userFormMode,
      AdministrationPagination,
      sidePanelState,
      usersToReplace,
      SidePanelState,
      goBackToEditForm,
      replaceUserAndDelete,
      useTranslations,
      selectedUser

    };
  },
});
