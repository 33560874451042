import { ActionTree } from 'vuex';
import {
  AddNewUser,
  ChangePassword,
  EditUser,
  State,
} from '@/views/administration/views/administration-users/administration-users.types';
import {
  createEntity,
  deleteSingleEntity,
  getEntitiesToReplace,
  getSingleEntity,
  getTableData,
  replaceEntity,
  updatePassword,
  updateSingleEntity,
} from '@/views/administration/administration.service';
import { RootState } from '@/store/store.types';
import i18n from '@/i18n';
import { GLOBAL } from '@/helpers/variables';
import { Notification } from '@/components/notification/notification';
import { AdministrationParams, SidePanelState } from '@/types';
import {
  AdministrationEndpoints,
  AdministrationPagination,
  DeleteEntityResponse,
} from '@/views/administration/administration.types';
import { RESPONSE_DATA } from '@/service/service';

export const actions: ActionTree<State, RootState> = {
  async getAllUsers({ commit, state, rootState }): Promise<void> {
    try {
      const { pageSize, currentPage } = rootState.pagination[
        AdministrationPagination.USERS
      ];
      const params: Partial<AdministrationParams> = state.tableParams;

      const allParams: Partial<AdministrationParams> = {
        ...params,
        page: currentPage,
        size: pageSize,
      };

      const { data } = await getTableData(
        AdministrationEndpoints.USERS,
        allParams
      );
      commit('setAllUsers', data.content);
      commit(
        'updatePaginationElements',
        {
          totalElements: data.totalElements,
          name: AdministrationPagination.USERS,
        },
        { root: true }
      );
    } catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  },

  async getSelectedUser({ commit }, userUuid: string): Promise<void> {
    try {
      const { data } = await getSingleEntity(
        AdministrationEndpoints.USERS,
        userUuid
      );
      commit('setSelectedUser', data);
    } catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  },
  async deleteUser({ dispatch }, userUuid: string): Promise<void> {
    try {
      await deleteSingleEntity(AdministrationEndpoints.USERS, userUuid);
      Notification.success(
        i18n.global.t(`${GLOBAL}.success.success`),
        i18n.global.t('users.notifications.delete')
      );
    } catch (e) {
      e.data.errorCode === DeleteEntityResponse.DELETE_RESTRICT
        ? dispatch('getUsersToReplace', userUuid)
        : Notification.error(
          i18n.global.t(`${GLOBAL}.errors.error`),
          i18n.global.t(`${GLOBAL}.errors.${e.data.message}`)
        );
      throw e.data;
    }
  },

  async createNewUser(context, payload: AddNewUser): Promise<void> {
    try {
      await createEntity(AdministrationEndpoints.USERS, payload);
      Notification.success(
        i18n.global.t(`${GLOBAL}.success.success`),
        i18n.global.t('users.notifications.success')
      );
    } catch (e) {
      if (e.data === RESPONSE_DATA.EMAIL_NOT_AVAILABLE) {
        Notification.error(
          i18n.global.t(`${GLOBAL}.errors.error`),
          i18n.global.t(`${GLOBAL}.errors.emailOccupied`)
        );
      } else {
        Notification.error(
          i18n.global.t(`${GLOBAL}.errors.error`),
          i18n.global.t(`${GLOBAL}.errors.${e.data}`)
        );
      }
      throw e.data;
    }
  },
  async editUserEntity(
    context,
    payload: { uuid: string; data: EditUser }
  ): Promise<void> {
    try {
      await updateSingleEntity(
        AdministrationEndpoints.USERS,
        payload.uuid,
        payload.data
      );
      Notification.success(
        i18n.global.t(`${GLOBAL}.success.success`),
        i18n.global.t('users.notifications.success')
      );
    } catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        e.data.violations[0].message
      );
      throw e.data;
    }
  },

  async editUserPassword(
    context,
    payload: { uuid: string; data: ChangePassword }
  ): Promise<void> {
    try {
      await updatePassword(
        AdministrationEndpoints.USERS,
        payload.uuid,
        payload.data
      );
      Notification.success(
        i18n.global.t(`${GLOBAL}.success.success`),
        i18n.global.t('users.notifications.success')
      );
    } catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  },

  async getGroups({ commit }): Promise<void> {
    try {
      const { data } = await getTableData(AdministrationEndpoints.GROUPS, {});
      commit('setGroupsData', data.content);
    } catch (e) {
      throw e.data;
    }
  },

  async getUsersToReplace({ commit }, userUuid: string) {
    try {
      const { data } = await getEntitiesToReplace(
        AdministrationEndpoints.USERS,
        userUuid
      );
      commit('setUsersToReplace', data);
      commit('setSidePanelState', SidePanelState.DELETE_ENTITY);
    } catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  },

  async replaceUser(
    context,
    {
      userUuid,
      replaceToUserUuid,
    }: { userUuid: string; replaceToUserUuid: string }
  ) {
    try {
      await replaceEntity(
        AdministrationEndpoints.USERS,
        userUuid,
        replaceToUserUuid
      );
      Notification.success(
        i18n.global.t(`${GLOBAL}.success.success`),
        i18n.global.t('users.notifications.replace')
      );
    } catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  },
};
