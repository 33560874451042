import { GetterTree } from 'vuex';
import { State } from '@/views/administration/views/administration-users/administration-users.types';
import { RootState } from '@/store/store.types';

export const getters: GetterTree<State, RootState> = {
  usersTableData(state) {
    if (state.users) {
      return state.users.map((user) => ({
        ...user,
        actions: [{ icon: 'iconActionLock', name: 'ChangePassword' }],
      }));
    }

    return [];
  },
  selectedUser(state) {
    if (state.selectedUser) {
      const { selectedUser } = state;
      const groups = state.selectedUser.group.map((group) => group.uuid);
      return {
        name: selectedUser.name,
        lastName: selectedUser.lastName,
        groupUuids: groups,
        status: selectedUser.status === 'ACTIVE' ? true : false,
        role: selectedUser.role,
        email: selectedUser.email,
        permissions: selectedUser.permissions,
        language: selectedUser.language,
        uuid: selectedUser.uuid,
        phoneNumber: selectedUser.phoneNumber,
        controllerNotification: selectedUser.controllerNotification,
        rejectionNotification: selectedUser.rejectionNotification,
        emailIssuesDeadlineEnabled: selectedUser.emailIssuesDeadlineEnabled,
        emailIssuesBrieflyToDeadlineEnabled:
          selectedUser.emailIssuesBrieflyToDeadlineEnabled,
        emailIssuesEnabled: selectedUser.emailIssuesEnabled,
        assignedNotification: selectedUser.assignedNotification,
        smsNotificationsEnabled: selectedUser.smsNotificationsEnabled,
      };
    }
    return null;
  },
  usersToReplace(state) {
    return state.usersToReplace.map((user) => ({
      uuid: user.uuid,
      label: `${user.name} ${user.lastName}`,
    }));
  },
};
