import {
  AddNewUser,
  ChangePassword,
  EditUser,
  UserFormMode,
} from '../administration-users.types';

export const setInitialState = (
  userForm: UserFormMode
): AddNewUser | EditUser | ChangePassword => {
  if (userForm === UserFormMode.EDIT) {
    return {
      name: '',
      lastName: '',
      email: '',
      status: true,
      groupUuids: [],
      role: '',
      permissions: [],
      phoneNumber: '',
      controllerNotification: false,
      rejectionNotification: false,
      emailIssuesDeadlineEnabled: false,
      emailIssuesBrieflyToDeadlineEnabled: false,
      emailIssuesEnabled: false,
      assignedNotification: false,
      smsNotificationsEnabled: false,
    };
  } else if (userForm === UserFormMode.CHANGE_PASSWORD) {
    return {
      password: '',
      repeatPassword: '',
    };
  }
  return {
    name: '',
    lastName: '',
    email: '',
    status: true,
    password: '',
    repeatPassword: '',
    groupUuids: [],
    role: '',
    permissions: [],
    phoneNumber: '',
    controllerNotification: false,
    rejectionNotification: false,
    emailIssuesDeadlineEnabled: false,
    emailIssuesBrieflyToDeadlineEnabled: false,
    emailIssuesEnabled: false,
    assignedNotification: false,
    smsNotificationsEnabled: false,
  };
};
