
import {
  computed,
  ComputedRef,
  defineComponent,
  PropType,
  reactive,
  toRefs,
  ref,
} from 'vue';
import {
  useGlobalTranslations,
  useTranslations,
} from '@/hooks/use-translations';
import { useForm } from '@/hooks/use-form';
import CCustomSwitch from '@/components/common/custom-switch/custom-switch.component.vue';
import CFormInput from '@/components/common/form/form-input/form-input.component.vue';
import { FormState, switchTypes } from '@/types';
import {
  AddNewUser,
  ChangePassword,
  EditUser,
  UserFormMode,
} from '../administration-users.types';
import { setInitialState } from '@/views/administration/views/administration-users/helpers/set-state';
import { useStore } from 'vuex';
import CFormRadioGroup from '@/components/common/form/form-radio-group/form-radio-group.component.vue';
import CAdministrationFormButtons from '@/views/administration/components/administration-form-buttons/administration-form-buttons.component.vue';
import CFormCheckboxGroup from '@/components/common/form/form-checkbox-group/form-checkbox-group.component.vue';
import { Notification } from '@/components/notification/notification';
import i18n from '@/i18n';
// import { helpers, required } from "@vuelidate/validators";

import { GLOBAL } from '@/helpers/variables';
import { cloneDeep } from 'lodash-es';

export default defineComponent({
  name: 'CUserForm',
  components: {
    CFormCheckboxGroup,
    CAdministrationFormButtons,
    CFormRadioGroup,
    CFormInput,
    CCustomSwitch,
  },
  props: {
    isModeChange: {
      type: Boolean,
      default: false,
    },
    userFormMode: {
      type: String as PropType<UserFormMode>,
      required: false,
      default: UserFormMode.ADD,
    },
  },
  emits: ['close-panel'],
  setup(props, { emit }) {
    const store = useStore();
    const allGroups = computed(() => store.state.views.users.groups);
    const currentUserUuid: string = store.state.user.uuid;
    const userData = computed(() => store.getters['views/users/selectedUser']);

    const isCurrentLoggedUser: ComputedRef<boolean> = computed(() => {
      return userData.value ? currentUserUuid === userData.value.uuid : false;
    });

    const roleType = [
      { name: 'Admin', value: 'ROLE_ADMIN' },
      { name: 'User', value: 'ROLE_USER' },
    ];
    const configurationTypes = ['ISSUES', 'ALL'];
    const languages = [
      { name: 'PL', value: 'PL' },
      { name: 'EN', value: 'EN' },
      { name: 'UK', value: 'UK' },
    ];

    const initialFormState:
      | ChangePassword
      | EditUser
      | AddNewUser = setInitialState(props.userFormMode);

    const state: FormState =
      userData.value && props.userFormMode === UserFormMode.EDIT
        ? reactive({ ...userData.value })
        : reactive({ ...userData.value, ...initialFormState });

    const { resetForm, validator } = useForm(state);

    const removeUser = async (): Promise<void> => {
      if (props.userFormMode === UserFormMode.EDIT) {
        await store.dispatch('views/users/deleteUser', userData.value.uuid);
        await store.dispatch('views/users/getAllUsers');

        emit('close-panel');
      }
    };

    const phoneRegex = new RegExp(/^\+(?:[0-9] ?){10}[0-9]$/);
    const isValidPhoneNumber = computed(() => {
      return (
        !state.smsNotificationsEnabled ||
        phoneRegex.test(state.phoneNumber as string)
      );
    });

    // const rules = computed(() => ({
    //   phoneNumber: {
    //     required,
    //     regex: helpers.regex(phoneRegex),
    //   },
    // }));

    const handleSubmit = async (): Promise<boolean> => {
      await validator.value.$touch();
      if (!validator.value.$error && isValidPhoneNumber.value) {
        const payload = {
          ...state,
          email: (state.email as string).toLowerCase(),
          status: state.status ? 'ACTIVE' : 'INACTIVE',
          phoneNumber: state.smsNotificationsEnabled ? state.phoneNumber : '',
        };
        if (props.userFormMode === UserFormMode.EDIT) {
          await store.dispatch('views/users/editUserEntity', {
            uuid: userData.value.uuid,
            data: payload,
          });
        } else if (props.userFormMode === UserFormMode.CHANGE_PASSWORD) {
          await store.dispatch('views/users/editUserPassword', {
            uuid: userData.value.uuid,
            data: state,
          });
        } else {
          await store.dispatch('views/users/createNewUser', payload);
        }
        await store.dispatch('views/users/getAllUsers');
        resetForm(state, cloneDeep(initialFormState));
        return true;
      } else {
        if (!isValidPhoneNumber.value) {
          Notification.error(
            i18n.global.t(`${GLOBAL}.warning.phone`),
            i18n.global.t(`${GLOBAL}.warning.phone_dial`)
          );
        }
        if (validator.value.$error) {
          Notification.warning(
            i18n.global.t(`${GLOBAL}.warning.warning`),
            i18n.global.t(`${GLOBAL}.warning.validation`)
          );
        }
      }
      return false;
    };

    const saveUserData = () => {
      handleSubmit()
        .then((isResolved) => {
          if (isResolved) {
            emit('close-panel');
          }
        })
        .catch((error) => {
          // console.log(error);
        });
    };

    return {
      languages,
      validator,
      allGroups,
      roleType,
      useTranslations,
      handleSubmit,
      saveUserData,
      ...toRefs(state),
      removeUser,
      configurationTypes,
      UserFormMode,
      useGlobalTranslations,
      isCurrentLoggedUser,
      switchTypes,
      isValidPhoneNumber,
    };
  },
});
