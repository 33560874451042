import { useVuelidate } from '@vuelidate/core';
import {
  email,
  integer,
  minLength,
  required,
  requiredIf,
  sameAs,
  helpers,
} from '@vuelidate/validators';
import { cloneDeep } from 'lodash-es';
import { computed, reactive, Ref } from '@vue/reactivity';
import { FormState } from '@/types';

interface UseForm<T extends FormState> {
  resetForm: (formState: T, initialFormState: T) => void;
  validator: Ref;
}

export const useForm = <T extends FormState>(formState: T): UseForm<T> => {
  const rules = computed(() =>
    Object.fromEntries(
      Object.entries(formState).map((value) => {
        if (value[0] === 'password') {
          return [value[0], { required, minLength: minLength(6) }];
        }

        if (value[0] === 'repeatPassword' && formState.password) {
          return [value[0], { required, sameAs: sameAs(formState.password) }];
        }

        if (value[0] === 'email') {
          return [value[0], { required, email }];
        }
        if (value[0] === 'phoneNumber') {
          const phoneRegex = new RegExp(/^\+(?:[0-9] ?){10}[0-9]$/);
          return [value[0], {}];
        }
        if (Array.isArray(value[1])) {
          return [value[0], { required: requiredIf(value[1].length === 0) }];
        }

        if (typeof value[1] === 'string') {
          return [value[0], { required }];
        }

        if (typeof value[1] === 'number') {
          return [value[0], { required, integer }];
        }

        return [value[0], {}];
      })
    )
  );

  const validator = useVuelidate(rules, formState);

  const resetForm = (formStateValue: T, initalFormState: T): void => {
    Object.assign(formStateValue, reactive(cloneDeep(initalFormState)));
    validator.value.$reset();
  };

  return {
    resetForm,
    validator,
  };
};
