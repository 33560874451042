import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, withKeys as _withKeys } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { key: 2 }
const _hoisted_3 = { class: "flex flex-col" }
const _hoisted_4 = { class: "mt-f13 text-14 pt-f5 leading-8 text-gray-600" }
const _hoisted_5 = { class: "flex flex-wrap" }
const _hoisted_6 = { class: "flex flex-wrap" }
const _hoisted_7 = { class: "flex flex-wrap" }
const _hoisted_8 = { class: "c-notifications-section__switch-wrapper flex items-center justify-between mt-6" }
const _hoisted_9 = { class: "inline-block w-1/2 normal-case" }
const _hoisted_10 = { class: "c-notifications-section__switch-wrapper flex items-center justify-between mt-6" }
const _hoisted_11 = { class: "inline-block w-1/2 normal-case" }
const _hoisted_12 = { class: "c-notifications-section__switch-wrapper flex items-center justify-between mt-6" }
const _hoisted_13 = { class: "inline-block w-1/2 normal-case" }
const _hoisted_14 = { class: "c-notifications-section__switch-wrapper flex items-center justify-between mt-6" }
const _hoisted_15 = { class: "inline-block w-1/2 normal-case" }
const _hoisted_16 = { class: "c-notifications-section__switch-wrapper flex items-center justify-between mt-6" }
const _hoisted_17 = { class: "inline-block w-1/2 normal-case" }
const _hoisted_18 = { class: "c-notifications-section__switch-wrapper flex items-center justify-between mt-6" }
const _hoisted_19 = { class: "inline-block w-1/2 normal-case" }
const _hoisted_20 = { class: "c-notifications-section__switch-wrapper flex items-center justify-between mt-6" }
const _hoisted_21 = { class: "inline-block w-1/2 normal-case" }
const _hoisted_22 = {
  key: 3,
  class: "c-notifications-section__switch-wrapper"
}
const _hoisted_23 = { class: "flex flex-col h-full justify-end mt-f34" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_form_input = _resolveComponent("c-form-input")
  const _component_c_custom_switch = _resolveComponent("c-custom-switch")
  const _component_c_form_checkbox_group = _resolveComponent("c-form-checkbox-group")
  const _component_c_form_radio_group = _resolveComponent("c-form-radio-group")
  const _component_c_administration_form_buttons = _resolveComponent("c-administration-form-buttons")

  return (_openBlock(), _createBlock("div", {
    class: "py-f21 px-f21 h-full flex-col flex justify-between text-gray-900 text-14 capitalize outline-none",
    tabindex: "0",
    onKeypress: _cache[21] || (_cache[21] = _withKeys(
//@ts-ignore
(...args) => (_ctx.saveUserData && _ctx.saveUserData(...args)), ["enter"]))
  }, [
    (
        _ctx.userFormMode === _ctx.UserFormMode.ADD || _ctx.userFormMode === _ctx.UserFormMode.EDIT
      )
      ? (_openBlock(), _createBlock(_component_c_form_input, {
          key: 0,
          modelValue: _ctx.email,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.email = $event)),
          error: _ctx.validator.email.$error ? 'email' : null,
          label: _ctx.useTranslations('form.labels.email'),
          placeholder: _ctx.useTranslations('form.labels.email'),
          required: !!_ctx.validator.email.required
        }, null, 8, ["modelValue", "error", "label", "placeholder", "required"]))
      : _createCommentVNode("", true),
    (
        _ctx.userFormMode === _ctx.UserFormMode.ADD ||
          _ctx.userFormMode === _ctx.UserFormMode.CHANGE_PASSWORD
      )
      ? (_openBlock(), _createBlock("div", _hoisted_1, [
          _createVNode(_component_c_form_input, {
            modelValue: _ctx.password,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.password = $event)),
            "dynamic-error-parameters": [6],
            error: _ctx.validator.password.$error ? 'passwordIsTooShort' : null,
            label: _ctx.useTranslations('form.labels.password'),
            placeholder: _ctx.useTranslations('form.labels.password'),
            required: !!_ctx.validator.password.required,
            type: "password"
          }, null, 8, ["modelValue", "error", "label", "placeholder", "required"]),
          _createVNode(_component_c_form_input, {
            modelValue: _ctx.repeatPassword,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.repeatPassword = $event)),
            error: _ctx.validator.repeatPassword.$error ? 'notMatchToPassword' : null,
            label: _ctx.useTranslations('form.labels.passwordConfirmation'),
            placeholder: _ctx.useTranslations('form.labels.passwordConfirmation'),
            required: !!_ctx.validator.repeatPassword.required,
            type: "password"
          }, null, 8, ["modelValue", "error", "label", "placeholder", "required"])
        ]))
      : _createCommentVNode("", true),
    (
        _ctx.userFormMode === _ctx.UserFormMode.ADD || _ctx.userFormMode === _ctx.UserFormMode.EDIT
      )
      ? (_openBlock(), _createBlock("div", _hoisted_2, [
          _createVNode(_component_c_form_input, {
            modelValue: _ctx.name,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.name = $event)),
            error: _ctx.validator.name.$error ? 'required' : null,
            label: _ctx.useTranslations('form.labels.name'),
            placeholder: _ctx.useTranslations('form.labels.name'),
            required: !!_ctx.validator.name.required
          }, null, 8, ["modelValue", "error", "label", "placeholder", "required"]),
          _createVNode(_component_c_form_input, {
            modelValue: _ctx.lastName,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_ctx.lastName = $event)),
            error: _ctx.validator.lastName.$error ? 'required' : null,
            label: _ctx.useTranslations('form.labels.lastName'),
            placeholder: _ctx.useTranslations('form.labels.lastName'),
            required: !!_ctx.validator.lastName.required
          }, null, 8, ["modelValue", "error", "label", "placeholder", "required"]),
          _createVNode("div", _hoisted_3, [
            _createVNode("span", _hoisted_4, _toDisplayString(_ctx.useTranslations("form.labels.status")), 1),
            _createVNode(_component_c_custom_switch, {
              modelValue: _ctx.status,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (_ctx.status = $event)),
              "left-label": "active",
              "right-label": "inactive",
              type: "double",
              onInput: _cache[7] || (_cache[7] = ($event: any) => (_ctx.status = !_ctx.status))
            }, null, 8, ["modelValue"])
          ]),
          _createVNode("div", _hoisted_5, [
            _createVNode(_component_c_form_checkbox_group, {
              modelValue: _ctx.groupUuids,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (_ctx.groupUuids = $event)),
              class: "mb-1",
              error: _ctx.validator.groupUuids.$error ? 'required' : null,
              group: _ctx.allGroups,
              label: _ctx.useTranslations('form.labels.userGroups'),
              required: !!_ctx.validator.groupUuids.required
            }, null, 8, ["modelValue", "error", "group", "label", "required"])
          ]),
          _createVNode(_component_c_form_radio_group, {
            modelValue: _ctx.role,
            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (_ctx.role = $event)),
            error: _ctx.validator.role.$error ? 'required' : null,
            group: _ctx.roleType,
            label: _ctx.useTranslations('form.labels.roles'),
            required: !!_ctx.validator.role.required
          }, null, 8, ["modelValue", "error", "group", "label", "required"]),
          _createVNode("div", _hoisted_6, [
            _createVNode(_component_c_form_checkbox_group, {
              modelValue: _ctx.permissions,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (_ctx.permissions = $event)),
              error: _ctx.validator.permissions.$error ? 'required' : null,
              group: _ctx.configurationTypes,
              label: _ctx.useTranslations('form.labels.permissions'),
              required: !!_ctx.validator.permissions.required
            }, null, 8, ["modelValue", "error", "group", "label", "required"])
          ]),
          _createVNode("div", _hoisted_7, [
            _createVNode(_component_c_form_radio_group, {
              modelValue: _ctx.language,
              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (_ctx.language = $event)),
              group: _ctx.languages,
              label: _ctx.useTranslations('form.labels.language')
            }, null, 8, ["modelValue", "group", "label"])
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode("div", _hoisted_8, [
      _createVNode("span", _hoisted_9, _toDisplayString(_ctx.useGlobalTranslations("labels.assignedNotificationAdmin")), 1),
      _createVNode(_component_c_custom_switch, {
        modelValue: _ctx.assignedNotification,
        "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => (_ctx.assignedNotification = $event)),
        class: "h-full infline-flex items-center",
        "left-label": _ctx.useGlobalTranslations('labels.on'),
        "right-label": _ctx.useGlobalTranslations('labels.off'),
        type: _ctx.switchTypes.DOUBLE
      }, null, 8, ["modelValue", "left-label", "right-label", "type"])
    ]),
    _createVNode("div", _hoisted_10, [
      _createVNode("span", _hoisted_11, _toDisplayString(_ctx.useGlobalTranslations("labels.controllerNotificationAdmin")), 1),
      _createVNode(_component_c_custom_switch, {
        modelValue: _ctx.controllerNotification,
        "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => (_ctx.controllerNotification = $event)),
        class: "h-full infline-flex items-center",
        "left-label": _ctx.useGlobalTranslations('labels.on'),
        "right-label": _ctx.useGlobalTranslations('labels.off'),
        type: _ctx.switchTypes.DOUBLE
      }, null, 8, ["modelValue", "left-label", "right-label", "type"])
    ]),
    _createVNode("div", _hoisted_12, [
      _createVNode("span", _hoisted_13, _toDisplayString(_ctx.useGlobalTranslations("labels.rejectionNotificationAdmin")), 1),
      _createVNode(_component_c_custom_switch, {
        modelValue: _ctx.rejectionNotification,
        "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => (_ctx.rejectionNotification = $event)),
        class: "h-full infline-flex items-center",
        "left-label": _ctx.useGlobalTranslations('labels.on'),
        "right-label": _ctx.useGlobalTranslations('labels.off'),
        type: _ctx.switchTypes.DOUBLE
      }, null, 8, ["modelValue", "left-label", "right-label", "type"])
    ]),
    _createVNode("div", _hoisted_14, [
      _createVNode("span", _hoisted_15, _toDisplayString(_ctx.useGlobalTranslations("labels.sendMailWithTaskExpiredAdmin")), 1),
      _createVNode(_component_c_custom_switch, {
        modelValue: _ctx.emailIssuesDeadlineEnabled,
        "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => (_ctx.emailIssuesDeadlineEnabled = $event)),
        class: "h-full infline-flex items-center",
        "left-label": _ctx.useGlobalTranslations('labels.on'),
        "right-label": _ctx.useGlobalTranslations('labels.off'),
        type: _ctx.switchTypes.DOUBLE
      }, null, 8, ["modelValue", "left-label", "right-label", "type"])
    ]),
    _createVNode("div", _hoisted_16, [
      _createVNode("span", _hoisted_17, _toDisplayString(_ctx.useGlobalTranslations("labels.sendMailWithTaskCloseToExpiredAdmin")), 1),
      _createVNode(_component_c_custom_switch, {
        modelValue: _ctx.emailIssuesBrieflyToDeadlineEnabled,
        "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => (_ctx.emailIssuesBrieflyToDeadlineEnabled = $event)),
        class: "h-full infline-flex items-center",
        "left-label": _ctx.useGlobalTranslations('labels.on'),
        "right-label": _ctx.useGlobalTranslations('labels.off'),
        type: _ctx.switchTypes.DOUBLE
      }, null, 8, ["modelValue", "left-label", "right-label", "type"])
    ]),
    _createVNode("div", _hoisted_18, [
      _createVNode("span", _hoisted_19, _toDisplayString(_ctx.useGlobalTranslations("labels.sendMailWithAllTasksAdmin")), 1),
      _createVNode(_component_c_custom_switch, {
        modelValue: _ctx.emailIssuesEnabled,
        "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => (_ctx.emailIssuesEnabled = $event)),
        class: "h-full infline-flex items-center",
        "left-label": _ctx.useGlobalTranslations('labels.on'),
        "right-label": _ctx.useGlobalTranslations('labels.off'),
        type: _ctx.switchTypes.DOUBLE
      }, null, 8, ["modelValue", "left-label", "right-label", "type"])
    ]),
    _createVNode("div", _hoisted_20, [
      _createVNode("span", _hoisted_21, _toDisplayString(_ctx.useGlobalTranslations("labels.smsNotificationAdmin")), 1),
      _createVNode(_component_c_custom_switch, {
        modelValue: _ctx.smsNotificationsEnabled,
        "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => (_ctx.smsNotificationsEnabled = $event)),
        class: "h-full infline-flex items-center",
        "left-label": _ctx.useGlobalTranslations('labels.on'),
        "right-label": _ctx.useGlobalTranslations('labels.off'),
        type: _ctx.switchTypes.DOUBLE
      }, null, 8, ["modelValue", "left-label", "right-label", "type"])
    ]),
    (_ctx.smsNotificationsEnabled)
      ? (_openBlock(), _createBlock("div", _hoisted_22, [
          _createVNode(_component_c_form_input, {
            modelValue: _ctx.phoneNumber,
            "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => (_ctx.phoneNumber = $event)),
            error: !_ctx.isValidPhoneNumber,
            label: _ctx.useGlobalTranslations('labels.phoneNumberInput'),
            placeholder: "+XX XXX XXX\n      XXX"
          }, null, 8, ["modelValue", "error", "label"])
        ]))
      : _createCommentVNode("", true),
    _createVNode("div", _hoisted_23, [
      _createVNode(_component_c_administration_form_buttons, {
        "form-mode": _ctx.userFormMode,
        "is-current-logged-user": _ctx.isCurrentLoggedUser,
        name: "User",
        onAddNext: _ctx.handleSubmit,
        onCancel: _cache[20] || (_cache[20] = ($event: any) => (_ctx.$emit('close-panel'))),
        onRemove: _ctx.removeUser,
        onSave: _ctx.saveUserData
      }, null, 8, ["form-mode", "is-current-logged-user", "onAddNext", "onRemove", "onSave"])
    ])
  ], 32))
}