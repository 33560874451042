import { MutationTree } from 'vuex';
import {
  State,
  User,
}                                                          from '@/views/administration/views/administration-users/administration-users.types';
import { AdministrationParams, FilterTypes, SidePanelState } from '@/types';
import {
  columnTypes,
  TableColumn,
}                                                          from '@/components/common/table/table.types';

export const mutations: MutationTree<State> = {
  setAllUsers(state, payload: User[]): void {
    if(payload[0]?.group){
      state.users = payload.map((user: User) => {
        return {
          ...user,
          groups: user.group
        }
      })
    } else {
      state.users = payload;
    }
  },
  setSelectedUser(state, user: User): void {
    state.selectedUser = user;
  },
  setTableParams(state, params: AdministrationParams): void {
    state.tableParams = params;
  },
  setGroupsData(state, groups: []): void {
    const columns: TableColumn[] = [
      { name: 'email', type: columnTypes.TEXT, filter: FilterTypes.SORT },
      { name: 'name', type: columnTypes.TEXT, filter: FilterTypes.SORT },
      { name: 'lastName', type: columnTypes.TEXT, filter: FilterTypes.SORT },
      {
        name: 'groups',
        type: columnTypes.TAGS,
        filter: FilterTypes.MULTI_SELECT,
        filterOptions: [...groups],
        selectedOptions: [],
      },
      { name: 'actions', type: columnTypes.ACTIONS },
    ];
    state.columns = columns;
    state.groups = groups;
  },
  setSidePanelState(state, payload: SidePanelState) {
    state.sidePanelState = payload
  },
  setUsersToReplace(state, payload: User[]) {
    state.usersToReplace = payload
  }
};
